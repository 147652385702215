import React from "react";
// import { graphql } from "gatsby";

import Root from "../Root";
import SEO from "../seo";
import AlgorithmProfile from "./AlgorithmProfile";
import AlgorithmCoins from "./AlgorithmCoins";
import AlgorithmSecondProfile from "./AlgorithmSecondProfile";
import NavajoAboveTable from "../Navajo/NavajoAboveTable";
import NavajoZillaBottom from "../Navajo/NavajoZillaBottom";
import BreadcrumsControls from "../Constant/BreadcrumsControls";

// export const query = graphql`
//   query($mongodb_id: String!) {
//     mongodbWheretomineAlgorithms(mongodb_id: { eq: $mongodb_id }) {
//       name
//       numberOfSupportedCoins
//       slug
//       description
//       mongodb_id
//       defaults {
//         powerConsumption
//         userHashrate
//       }
//       isListed
//       links {
//         name
//         platform
//         url
//       }
//     }
//     allMongodbWheretomineCoins(filter: { algorithm: { eq: $mongodb_id } }) {
//       edges {
//         node {
//           mongodb_id
//           name
//           slug
//           abbreviation
//           hasCalculator
//           numberOfSubPools
//         }
//       }
//     }
//   }
// `;

const Algorithm = ({ pageContext }) => {
  const { algorithm } = pageContext;

  const breadcrums = [
    { to: "/", page: "Home" },
    { to: "/algorithms", page: "Algorithms" },
  ];

  return (
    <Root>
      <SEO
        title={`List Of All ${algorithm.data.name} Coins`}
        description={`See what ${algorithm.data.name} coins to mine and view their most profitable mining pools by checking the pool fee, payment scheme or minimum payout.`}
      />
      <BreadcrumsControls breadcrums={breadcrums} currentPage={`${algorithm.data.name}`} />
      <AlgorithmProfile algorithm={algorithm.data} />
      <NavajoAboveTable />
      <AlgorithmCoins coins={algorithm.data.supportedCoins} />
      <AlgorithmSecondProfile algorithm={algorithm.data} />
      <NavajoZillaBottom />
    </Root>
  );
};

export default Algorithm;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby-theme-material-ui";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import GradientLine from "../Constant/GradientLine";
import BlurUpImage from "../Constant/BlurUpImage";

import { mediaUrl } from "../../constant/constant";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
  },
  breadcrums: {
    margin: "40px auto 40px",
  },
  header: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  description: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  tableRow: {
    display: "flex",
    borderBottom: "unset",
  },
  tableCell: {
    padding: "12px 0px 12px",
    display: "flex",
    alignItems: "center",
    borderBottom: "unset",
  },
  firstTableCell: {
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  normalTableCell: {
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  lastTableCell: {
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "25%",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkTitle: {
    fontSize: "1rem",
    lineHeight: "1.2rem",
    fontWeight: "600",
    letterSpacing: ".04em",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkSubtitle: {
    fontWeight: "500",
    fontSize: "0.75rem",
    letterSpacing: ".04em",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
}));

const AlgorithmCoins = props => {
  const { coins } = props;
  const classes = useStyles(props);

  return (
    <div>
      <GradientLine />
      <Container maxWidth="lg">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={clsx(classes.tableCell, classes.firstTableCell)} align="left" sortDirection={false}>
                  <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                    Cryptocurrency
                  </Typography>
                </TableCell>
                <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} align="left" sortDirection={false}>
                  <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                    Profit Calculator
                  </Typography>
                </TableCell>
                <TableCell className={clsx(classes.tableCell, classes.lastTableCell)} align="right" sortDirection={false}>
                  <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                    Pools
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: 0, borderBottom: "unset" }}>
                  <GradientLine />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coins.map((coin, index) => {
                const image = {
                  id: `${coin.slug}-preview`,
                  alt: coin.name,
                  title: coin.name,
                  src: `${mediaUrl}/assets/img/coins/${coin.abbreviation.toLowerCase()}.png`,
                  blurSrc: `${mediaUrl}/assets/img/coins/${coin.abbreviation.toLowerCase()}.png`,
                  size: { width: 28, height: 28 },
                };

                return (
                  <TableRow className={classes.tableRow} key={index} tabIndex={-1}>
                    <TableCell className={clsx(classes.tableCell, classes.firstTableCell)} component="th" scope="row" align="left">
                      <Link className={classes.link} to={`/coins/${coin.slug}`}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ minWidth: 28, marginRight: 8 }}>
                            <Typography className={classes.linkTitle} variant="subtitle1">
                              {index + 1}
                            </Typography>
                          </span>
                          <span style={{ width: 28, height: 28, marginRight: 8 }}>
                            <BlurUpImage image={image} />
                          </span>
                          <span>
                            <Typography className={classes.linkTitle} variant="subtitle1">
                              {coin.name}
                            </Typography>
                            <span className={classes.linkSubtitle}>{coin.abbreviation}</span>
                          </span>
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} component="th" scope="row" align="left">
                      <div className={classes.link}>
                        <Typography className={classes.linkTitle} variant="subtitle1">
                          No
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.lastTableCell)} component="th" scope="row" align="right">
                      <Link className={classes.link} to={`/coins/${coin.slug}`}>
                        <Typography className={classes.linkTitle} variant="subtitle1">
                          {coin.numberOfSubPools}
                        </Typography>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <GradientLine />
    </div>
  );
};

AlgorithmCoins.propTypes = {
  coins: PropTypes.arrayOf(PropTypes.object),
};

export default AlgorithmCoins;
